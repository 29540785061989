<template>
  <history-form mode="create"></history-form>
</template>

<script>
import VersionHistoryForm from "./VersionHistoryForm.vue";
export default {
  name: "VersionCreate",
  components: {
    HistoryForm: VersionHistoryForm,
  },
};
</script>
